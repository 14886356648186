<template>
  <b-modal no-fade v-model="showDialog" size="lg" :hide-footer="true"
           no-close-on-backdrop>
    <div>
      <div v-if="loading === false">
        <h3 class="text-right mb-5"> #{{id}}</h3>
        <smartLock-panel v-model="smartLock" />
        <div class="mt-2 text-center">
          <b-button variant="primary" class="mx-1" @click="save">{{ msg("Save") }}</b-button>
        </div>
      </div>
      <div v-else-if="this.error != null">
        <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
          <strong>{{ error }}</strong>
        </b-alert>
      </div>
      <div v-else>
        <b-spinner label="Spinning"/>
        {{ msg('loading') }}
      </div>
    </div>

  </b-modal>
</template>

<script>
import {mapActions} from 'vuex'
import SmartLockPanel from '@/components/panels/nuki/smartLockPanel'

export default {
  name: 'smartLockModal',
  components: {SmartLockPanel},
  data: () => ({
    uniqueId: "",
    loading: true,
    error: null,
    smartLock: {},
  }),
  props: {
    id: {
      required: true,
    },
    dialog: {
      required: true,
      default: false,
      type: Boolean
    },
  },
  watch: {
    id: function () {
      this.error = null
      this.onLoad()
    },
  },
  created() {
    this.uniqueId = this.id
    this.onLoad()
  },
  computed: {

    showDialog: {
      get() {
        return this.dialog === true
      },
      set(val) {
        this.$emit('update:dialog', (val === true))
      }
    },
  },
  methods: {
    ...mapActions('nuki', ['getNukiById', 'updateNuki']),
    onLoad() {
      this.loading = true;
      this.error = null;
      if (this.id == null || this.id === "")
        return

      this.getNukiById(this.id).then(this.onSmartLockLoaded, this.onError)
    },
    onSmartLockLoaded(smartLock) {
      this.loading = false;
      this.smartLock = smartLock;
    },
    onError(error) {
      this.error = error;
    },
    onCancel() {
      this.showDialog = false
    },
    save() {
      let $this = this;
      this.updateNuki(this.smartLock).then(() => {
        $this.$root.$emit('crm::smartLock::update');
        // $this.onLoad();
        this.showDialog = false

      })
    }
  }
}
</script>

<style scoped>

</style>
