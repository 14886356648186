<template>
  <div>
    <h4 class="mb-2" style="font-weight: bold;text-decoration: underline;">{{ msg('SmartLock details') }}</h4>
    <b-row class="mt-1">
      <b-col cols="12" md="6" lg="6">
        <b-form-group :label="msg('Nuki SmartLock Id')" label-for="token">
          <validation-provider #default="{ errors }" :name="msg('Nuki SmartLockId')" rules="required">
            <b-form-input readonly  id="SmartLockId" v-model="smartLock.smartLockId"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group :label="msg('Nuki name')" label-for="smartLock-name">
          <b-form-input readonly id="smartLock-name" v-model="smartLock.name"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="6">
        <b-form-group :label="msg('AccountId')" label-for="smartLock-token">
          <b-form-input readonly id="smartLock-token" v-model="smartLock.accountId"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" lg="6">
        <b-form-group :label="msg('SmartLock serial number')" label-for="smartLock-nukiId">
          <b-form-input readonly id="smartLock-nukiId" v-model="smartLock.nukiId"/>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" lg="6">
        <b-form-group :label="msg('Code')" label-for="smartLock-address">
          <b-form-input   id="smartLock-code" v-model="smartLock.code"/>
        </b-form-group>
      </b-col>


      <b-col cols="12" md="6" lg="6">
        <b-form-group :label="msg('Branch')" label-for="smartLock-branch">
          <v-select
              :dir="isRTL ? 'rtl' : 'ltr'"
              v-model="smartLock.buid"
              :options="allBranches"
              class="w-100"
              label="name"
              :reduce="(branch) => branch.id"
              :clearable="false"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <b-row no-gutters class="mb-2">
      <b-col cols="12">
        <h4 class="mb-2" style="font-weight: bold;text-decoration: underline;">{{ msg('SmartLock actions') }}</h4>
      </b-col>
      <b-col cols="12">
        <div style="display: inline-block" class="mr-2">
          <b-button size="lg"  variant="danger" @click="lock">{{ msg("Lock") }}</b-button>
        </div>
        <div style="display: inline-block">
          <b-button size="lg" variant="success" @click="unlock">{{ msg("Unlock") }}</b-button>
        </div>
      </b-col>
      <b-col cols="5" class="mr-1">
        <b-form-group :label="msg('Lock state')" label-for="smartLock-lockState">
          <b-form-input readonly id="smartLock-nukiId" v-model="smartLock.lockState"/>
        </b-form-group>

      </b-col>
      <b-col cols="6">
        <b-form-group :label="msg('Battery status')" label-for="smartLock-batteryState">
          <b-form-input readonly id="smartLock-batteryState" v-model="smartLock.batteryState"/>
        </b-form-group>

      </b-col>
    </b-row>
    <b-row no-gutters class="mb-2">
      <b-col cols="12">
        <div>
          <label>{{ msg("Note") }}</label>
          <b-textarea v-model="smartLock.note"></b-textarea>
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'smartLockPanel',
  props: {
    value: {
      required: true
    },
  },
  computed: {
    ...mapGetters('translator', ['msg']),
    ...mapGetters('data', {
      selectedBuIds: 'getSelectedBranchesIds',
      getBranchName: 'getBranchName',
      _getAllBranches: 'getAllBranches',
    }),

    allBranches() {
      return Object.values(this._getAllBranches)
    },
    smartLock: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

  }
  , methods: {
    ...mapActions('nuki', ['lockNukiSmartLock', 'unlockNukiSmartLock']),


    unlock() {
      let $this = this;
      this.unlockNukiSmartLock(this.smartLock.smartLockId).then(() => {
        $this.smartLock.lockState = "Unlocked";
      }, this.onError)

    },
    lock() {
      let $this = this;
      this.lockNukiSmartLock(this.smartLock.smartLockId).then(() => {
        $this.smartLock.lockState = "Locked";
      }, this.onError)

    },

  }
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-pricing.scss';
</style>
