<template>
  <li
      class="timeline-item"
      :class="[
        `timeline-variant-${variant }`,
        fillBorder ? `timeline-item-fill-border-${variant }` : null
      ]"
  >
    <!-- Timeline Point or Icon -->
    <div v-if="icon" class="timeline-item-icon d-flex align-items-center justify-content-center rounded-circle">
      <feather-icon :icon="icon" />
    </div>
    <div v-else class="timeline-item-point" />

    <!-- Content Slot -->
    <slot>
      <!-- Title and Badge -->
      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0 align-items-center">
        <b-badge :variant="variant" class="timeline-badge" v-text="title" />

        <!-- Export Button -->
        <b-button
            v-if="!exported"
            @click="markAsExported"
            variant="outline-success"
            class="ml-sm-auto mt-2 mt-sm-0"
            size="sm"
        >
          Mark as Exported
        </b-button>
      </div>

      <!-- Timeline Time -->
      <div>
        <slot name="time">
          <span style="font-weight: bolder">Created: </span><span class="timeline-item-time text-nowrap" style="color: rgba(254,81,81,0.71);font-weight: bold" v-text="time" />
        </slot>
      </div>

      <!-- Exported Date (if exported) -->
      <div v-if="exported">
        <slot name="time">
          <span style="font-weight: bolder">Exported at: </span><span class="timeline-item-time text-nowrap" style="color: rgba(23,130,21,0.71);font-weight: bold" v-text="exportedDate" />
        </slot>
      </div>
      <!-- Source Information -->
      <div>
        <slot name="source">
          <span style="font-weight: bolder">Source: </span> <span class="timeline-item-source mb-0 " style="font-weight: bolder" >{{source}}</span>
        </slot>
      </div>

      <!-- Channel Information with Copy Icon -->
      <div>
        <slot name="channel">
          <span style="font-weight: bolder">Channel: </span> <span class="timeline-item-source mb-0 " style="font-weight: bolder" >{{channel}}</span>
        </slot>
      </div>

      <!-- Account Email with Copy Icon -->
      <div>
        <slot name="link">
            <span class="mr-1 copy-icon" @click="copyToClipboard(account)">
              <i class="fas fa-clipboard"></i>
            </span>
            <span style="font-weight: bolder">Account: </span>
            <span>{{account}}</span>

        </slot>
      </div>

      <!-- Property ID with Copy Icon -->
      <div>
        <slot name="link">
          <span class="mr-1 copy-icon" @click="copyToClipboard(propertyId)">
              <i class="fas fa-clipboard"></i>
            </span>
            <span style="font-weight: bolder">Id: </span>
            <span>{{propertyId}}</span>

        </slot>
      </div>

      <!-- Property Link with Copy Icon -->
      <div>
        <slot name="link">
            <span class="mr-1 copy-icon" @click="copyToClipboard(propertyLink)">
              <i class="fas fa-clipboard"></i>
            </span>
            <span style="font-weight: bolder">Link: </span>
            <span>{{propertyLink}}</span>
        </slot>
      </div>


    </slot>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
    id: String, // our preoprty db id
    historyId: String, //
    channel: String,
    account: String,
    propertyId: String, // not our propertyId this is for the channel
    propertyLink: String,
    exported: {
      type: String,
      default: null,
    },
    exportedDate: {
      type: String,
      default: null,
    },

    title: {
      type: String,
      default: null,
    }, data: {
      type: String,
      default: null,
    },
    source: {
      type: String,
      default: null,
    },
    time: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    fillBorder: {
      type: Boolean,
      default: false,
    },

  },
  computed: {

  },
  methods: {

    markAsExported() {
      this.$emit('submitted', { realEstateId: this.id, historyId: this.historyId });

    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$bvToast.toast('Copied to clipboard!', {
          title: 'Success',
          variant: 'success',
          solid: true,
          autoHideDelay: 2000,
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>


@import '~@core/scss/base/bootstrap-extended/include';
// Bootstrap includes
@import '~@core/scss/base/components/include';
// Components includes

// Color palettes
@import '~@core/scss/base/core/colors/palette-variables.scss';

$timeline-border-color: $border-color;

/* Generate:
*  Apply background color to dot
*/
@each $color_name, $color in $colors {
  @each $color_type, $color_value in $color {
    @if $color_type== 'base' {
      .timeline-variant-#{$color_name} {
        &.timeline-item-fill-border-#{$color_name} {
          border-color: $color_value !important;

          &:last-of-type {
            &:after {
              background: linear-gradient($color_value, $white);
            }
          }
        }

        .timeline-item-point {
          background-color: $color_value;

          &:before {
            background-color: rgba($color_value, 0.12);
          }
        }

        .timeline-item-icon {
          color: $color_value;
          border: 1px solid $color_value;
        }
      }
    }
  }
}

.copy-icon {
  cursor: pointer;
  color: #dabc38;
}
.copy-icon:hover {
  color: #0056b3;
}

.timeline-item {
  padding-left: 2.5rem;
  position: relative;

  &:not(:last-of-type) {
    padding-bottom: 2rem;
    border-left: 1px solid $timeline-border-color;
  }

  // This gives shade to last timeline-item but isn't that revolutionary
  &:last-of-type {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: linear-gradient($timeline-border-color, $white);
    }
  }

  .timeline-item-point {
    position: absolute;
    left: -6px;
    width: 12px;
    height: 12px;
    top: 0;
    border-radius: 50%;
    z-index: 1;

    &:before {
      content: '';
      z-index: 1;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: 0;
    }
  }

  .timeline-item-icon {
    position: absolute;
    left: -12px;
    top: 0;
    width: 24px;
    height: 24px;
    background-color: $white;
    z-index: 1;
  }
}

// *===============================================---*
// *--------- Dark Layout ---------------------------------------*
// *===============================================---*

.dark-layout {
  .timeline-item {
    &:last-of-type {
      &:after {
        background: linear-gradient($theme-dark-border-color, $theme-dark-card-bg);
      }
    }

    &:not(:last-of-type) {
      border-left-color: $theme-dark-border-color;
    }

    .timeline-item-icon {
      background-color: $theme-dark-card-bg;
    }
  }
}

</style>
